import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "../assets/stylesheets/bootstrap.min.css";
import "../assets/stylesheets/style.css";
import "../assets/stylesheets/responsive.css";
import { getBlogDetail } from "../api/utils";
import useFetch from "../api/hooks/useFetch";

export default function BlogDetail() {
  const location = useLocation();

  const navigate = useNavigate();

  const id = location?.state?.item;

  const { apiData, isLoading, error } = useFetch(getBlogDetail, id);

  console.log(apiData, "apiData");

  return (
    <div>
      <main className="wrapper">
        <br />
        <br />
        <section className="page-hero-sec president-sec pt-0">
          <div className="px-3">
            <div
              className="col-lg-12"
              style={{ justifyContent: "center", display: "flex" }}
            >
              <div className="pt-4 pt-lg-0 px-5 container">
                <div className="d-flex flex-column align-items-center text-center">
                  <Link
                    className="tc-primary-light-bordered text-uppercase fs-12"
                    to={"/blogs"}
                  >
                    BLOGS
                  </Link>
                  <h1 className="tc-primary head-font fs-44 py-3">
                    {apiData.title}
                  </h1>
                </div>
              </div>
            </div>
            <div
              className="col-lg-12"
              style={{ justifyContent: "center", display: "flex" }}
            >
              <img
                src={apiData?.banner}
                className="img-fluid"
                style={{ backgroundSize: "contain", borderRadius: 7 }}
                alt=""
              />
            </div>
          </div>
        </section>
        <section>
          <div className="container py-5 pt-4">
            <div className="row justify-content-center pt-0 pt-lg-4">
              <div className="col-lg-12">
                <br></br>
                <br></br>

                <div
                  className="tc-primary fs-26 py-2 blog-content"
                  style={{
                    justifyContent: "center",
                    textAlign: "justify",
                  }}
                  dangerouslySetInnerHTML={{ __html: apiData?.content }}
                />

                <br></br>
              </div>
            </div>
            <hr />
            <div className="row justify-content-center pt-0 pt-lg-4">
              <div className="col-lg-12">
                <center>
                  <h3 className="tc-primary head-font py-3">
                    Supercharge your business with us
                  </h3>
                  <br />
                  <button
                    onClick={() => {
                      navigate("/contact");
                    }}
                    className="link_btn mx-2"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    Let’s Get In Touch
                  </button>
                </center>

                <div className="col-lg-3 ps-lg-5 d-lg-block d-none"></div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
