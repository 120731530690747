import React, { useState, useEffect } from "react";
import "../assets/stylesheets/bootstrap.min.css";
import "../assets/stylesheets/style.css";
import "../assets/stylesheets/responsive.css";
import { getCaseStudies } from "../api/utils";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useFetch from "../api/hooks/useFetch";
import moment from "moment";

export default function CaseStudies() {
  const navigate = useNavigate();

  const location = useLocation();
  const pathname = location.pathname;
  var params = new window.URLSearchParams(window.location.search);
  console.log(params.get("pt"));
  const serviceType = params.get("pt")?.length > 0 ? params.get("pt") : "All";

  const { apiData, isLoading, error } = useFetch(getCaseStudies, serviceType);

  console.log(serviceType, "filter");

  const toCaseStudy = (id) => {
    navigate(`/case_studies/${id}`, { state: { item: id } });
  };

  return (
    <div>
      <main className="wrapper">
        <section className="page-hero-sec">
          <div className="container text-center">
            <h1 className="tc-primary head-font">Case Studies</h1>
            <h1 className="tc-primary fs-20">
              Insights, Trends & Innovations – Stay Ahead in Software
              Development
            </h1>
          </div>
        </section>
        <hr className="hr-double" />
        <section>
          <div className="container py-5 pb-4">
            <div
              style={{
                justifyContent: "space-evenly",
                display: "flex",
              }}
            >
              <a
                className={
                  serviceType === "All"
                    ? "activePage tc-primary fs-18"
                    : "tc-primary fs-18"
                }
                href={"/case_studies?pt=All"}
              >
                All
              </a>

              <a
                className={
                  serviceType === "Web Development"
                    ? "activePage tc-primary fs-18"
                    : "tc-primary fs-18"
                }
                href={"/case_studies?pt=Web Development"}
              >
                Web Development
              </a>

              <a
                className={
                  serviceType === "Mobile Development"
                    ? "activePage tc-primary fs-18"
                    : "tc-primary fs-18"
                }
                href={"/case_studies?pt=Mobile Development"}
              >
                Mobile Development
              </a>

              <a
                className={
                  serviceType === "ERP Development"
                    ? "activePage tc-primary fs-18"
                    : "tc-primary fs-18"
                }
                href={"/case_studies?pt=ERP Development"}
              >
                Custom ERP Development
              </a>
            </div>
            <br />
            <div className="row">
              <br />
              <div className="col-lg-12">
                <hr />
                <br />
                {apiData?.map((c, index) => {
                  return (
                    <a
                      onClick={() => {
                        toCaseStudy(c?.id);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <h2
                        className="tc-primary head-font2 sm-caps-none"
                        style={{ textAlign: "justify" }}
                      >
                        {c?.title ?? "NA"}
                      </h2>
                      <p className="d-flex flex-wrap para-font align-items-center pt-3 fs-12">
                        <strong className="tc-primary-light">Posted</strong>
                        <span className="dot" />
                        <strong>
                          <strong className="tc-primary-light">
                            {moment(c?.posted_at).fromNow() ?? "NA"}
                          </strong>
                        </strong>
                      </p>
                      {index < apiData.length - 1 ? (
                        <hr className="my-4" />
                      ) : null}
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
