import React, { useState } from "react";
import "../assets/stylesheets/bootstrap.min.css";
import "../assets/stylesheets/style.css";
import "../assets/stylesheets/responsive.css";
import { getBlogs } from "../api/utils";
import useFetch from "../api/hooks/useFetch";
import moment from "moment";
import BlogCard from "../components/BlogCard";

export default function Blogs() {
  const [currentPage, setCurrentPage] = useState(1);

  const { apiData, isLoading, error } = useFetch(getBlogs, {});

  const pagesData = () => {
    let chunkSize = 6;
    let chunks = [];
    for (let i = 0; i < apiData?.length; i += chunkSize) {
      chunks?.push(apiData?.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const pageData = () => {
    return pagesData()[currentPage - 1];
  };

  return (
    <div>
      <main className="wrapper">
        <section className="page-hero-sec">
          <div className="container text-center">
            <h1 className="tc-primary head-font">Blogs</h1>
            <h1 className="tc-primary fs-20">
              Insights, Trends & Innovations – Stay Ahead in Software
              Development
            </h1>
          </div>
        </section>
        <hr className="hr-double" />
        <section>
          <div className="container py-5">
            <div className="row">
              <div className="row justify-content-center">
                {isLoading ? (
                  <p className="fs-18">Loading....</p>
                ) : (
                  pageData()?.map((i) => {
                    return <BlogCard item={i} key={i?.name} />;
                  })
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
