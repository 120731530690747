import React, { useState } from "react";
import { images } from "../constants/images";
import { Link, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import ModalComponent from "./ModalComponent";
import "../assets/stylesheets/bootstrap.min.css";
import "../assets/stylesheets/style.css";
import "../assets/stylesheets/responsive.css";

export default function Header() {
  const location = useLocation();
  const pathname = location.pathname;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const modalData = () => {
    return (
      <div className="menu-2">
        <div
          className="overlay_header"
          style={{ flexDirection: "row", display: "flex" }}
        >
          <a href="./" className="menu-2-brand-logo border-0">
            <img
              src={images?.pluralcodeLogo}
              alt=""
              style={{ height: "125%", width: "275%" }}
            />
          </a>
          <nav>
            <a
              onClick={handleClose}
              style={{ cursor: "pointer" }}
              className="menu-2-close border-0"
            >
              <img
                src={images?.cross}
                alt=""
                style={{ height: "30%", width: "25%", left: -20 }}
              />
            </a>
          </nav>
        </div>

        <div className="m2_navs">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-lg-3">
                <div className="d-flex flex-column">
                  <h3 className="mb-lg-4">
                    <Link
                      className={`tc-primary fs-28 head-font menu2-item ${
                        pathname == "/about" ? "activePage" : ""
                      }`}
                      to={"/about"}
                      onClick={handleClose}
                    >
                      About Us
                    </Link>
                  </h3>
                  <h3>
                    <Link
                      to={"/services"}
                      onClick={handleClose}
                      className={`tc-primary fs-28 head-font menu2-item ${
                        pathname == "/services" ? "activePage" : ""
                      }`}
                    >
                      Services
                    </Link>
                  </h3>
                </div>
              </div>
              <div className="col-md-4 col-lg-3">
                <div className="d-flex flex-column">
                  <h3 className="mb-lg-4">
                    <Link
                      to={"/technologies"}
                      onClick={handleClose}
                      className={`tc-primary fs-28 head-font menu2-item ${
                        pathname == "/technologies" ? "activePage" : ""
                      }`}
                    >
                      Technologies
                    </Link>
                  </h3>
                  {/* <h3>
                    <Link
                      to={"/portfolios"}
                      onClick={handleClose}
                      className={`tc-primary fs-28 head-font menu2-item ${
                        pathname.includes("/portfolio") ? "activePage" : ""
                      }`}
                    >
                      Portfolios
                    </Link>
                  </h3> */}
                  <h3>
                    <Link
                      to={"/case_studies"}
                      onClick={handleClose}
                      className={`tc-primary fs-28 head-font menu2-item ${
                        pathname.includes("/case_Studies") ? "activePage" : ""
                      }`}
                    >
                      Case Studies
                    </Link>
                  </h3>
                </div>
              </div>
              <div className="col-md-4 col-lg-3">
                <div className="d-flex flex-column">
                  <h3 className="mb-lg-4">
                    <Link
                      to={"/blogs"}
                      onClick={handleClose}
                      className={`tc-primary fs-28 head-font menu2-item ${
                        pathname == "/blogs" ? "activePage" : ""
                      }`}
                      style={pathname == "/blogs" ? { marginBottom: 25 } : null}
                    >
                      Blogs
                    </Link>
                  </h3>
                  <h3 className="mb-lg-4">
                    <Link
                      to={"/carrers"}
                      onClick={handleClose}
                      className={`tc-primary fs-28 head-font menu2-item ${
                        pathname == "/carrers" ? "activePage" : ""
                      }`}
                      style={
                        pathname == "/carrers" ? { marginBottom: 25 } : null
                      }
                    >
                      Careers
                    </Link>
                  </h3>
                </div>
              </div>
              <div className="col-md-4 col-lg-3">
                <div className="px-lg-4 px-0 pt-4 pt-lg-0 border-start">
                  <ul className="right-menus-items-list">
                    <li className="pb-3">
                      <Link
                        to={"/contact"}
                        onClick={handleClose}
                        className={`fs-14 tc-primary ${
                          pathname == "/contact" ? "activePage" : ""
                        }`}
                      >
                        <strong>Contact Us</strong>
                      </Link>
                    </li>
                  </ul>
                  <div className="d-flex align-items-center flex-wrap social-links py-4">
                    <a
                      href="https://www.instagram.com/pluralcode/?igshid=YmMyMTA2M2Y%3D"
                      target="_blank"
                      className="tc-primary fs-18"
                      onClick={handleClose}
                    >
                      <i className="fa-brands fa-instagram" />
                    </a>
                    <a
                      href="https://www.facebook.com/pluralcode"
                      target="_blank"
                      className="tc-primary fs-18"
                      onClick={handleClose}
                    >
                      <i className="fa-brands fa-facebook-f" />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/plural-code-technologies"
                      target="_blank"
                      className="tc-primary fs-18"
                      onClick={handleClose}
                    >
                      <i className="fa-brands fa-linkedin" />
                    </a>
                  </div>

                  <p className="fs-17 py-md-3 py-0 tc-primary">
                    <strong>Plural Code Technologies</strong>
                    <br />
                    A-1/129, Ground Floor,
                    <br />
                    Sector - 17, Rohini,
                    <br />
                    Delhi 110089, India
                    {/* <br />
                    <a className="tc-primary fs-14" href="telto:+91-9958920231">
                      +91-9958920231
                    </a> */}
                    <br />
                    <a
                      className="tc-primary fs-14"
                      href="mailto:info@pluralcode.com"
                    >
                      info@pluralcode.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <header>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css"
          integrity="sha512-9usAa10IRO0HhonpyAIVpjrylPvoDwiPUiKdWk5t3PyolY1cOd4DSE0Ga+ri4AuTroPR5aQvXU9xC6qOPnzFeg=="
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
        />

        <div className="brand-text">
          <Link
            to={"/"}
            className="text-uppercase tc-primary head-font border-0"
          >
            <span className="d-none d-md-block fs-24">
              Plural Code Technologies
            </span>
            <span className="d-md-none fs-14">Plural Code Technologies</span>
          </Link>
        </div>

        <nav>
          <ul>
            <li>
              <Link
                className={pathname == "/about" ? "activePage" : null}
                to={"/about"}
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                className={pathname == "/services" ? "activePage" : null}
                to={"/services"}
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                className={pathname == "/technologies" ? "activePage" : null}
                to={"/technologies"}
              >
                Technologies
              </Link>
            </li>
            {/* <li>
              <Link
                className={
                  pathname == "/portfolio"
                    ? "activePage"
                    : pathname.includes("portfolio")
                    ? "activePage"
                    : null
                }
                to={"/portfolios"}
              >
                Portfolios
              </Link>
            </li> */}
            <li>
              <Link
                className={
                  pathname == "/case_studies"
                    ? "activePage"
                    : pathname.includes("case_studies")
                    ? "activePage"
                    : null
                }
                to={"/case_studies"}
              >
                Case Studies
              </Link>
            </li>
            <li>
              <Link
                className={pathname == "/blogs" ? "activePage" : null}
                to={"/blogs"}
              >
                Blogs
              </Link>
            </li>
            <li>
              <Link
                className={pathname == "/careers" ? "activePage" : null}
                to={"/careers"}
              >
                Careers
              </Link>
            </li>
            <li>
              <Link
                className={pathname == "/contact" ? "activePage" : null}
                to={"/contact"}
              >
                Contact Us
              </Link>
            </li>
          </ul>

          <Button class="menu-toggler" onClick={handleOpen}>
            <span className="menu-bars bar1" />
            <span className="menu_btn_text">MENU</span>
            <span className="menu-bars bar2" />
          </Button>
        </nav>
      </header>
      <ModalComponent content={modalData()} open={open} setOpen={setOpen} />
    </>
  );
}
