export const BASEURL = "https://dashboard.pluralcode.com/api";

// Contact
export const CONTACT = BASEURL + "/contact_enquiries/new";

// Blogs
export const BLOGS = BASEURL + "/blogs";

// Case Studies
export const CASE_STUDIES = BASEURL + "/case_studies";

export const SOMETHING_WENT_WRONG = "Something went wrong";

// API Wrappers
export const returnOrThrow = async (resJSON) => {
  let result;
  const status = resJSON.status;
  if (status === 404 || status >= 500) {
    throw `${SOMETHING_WENT_WRONG}!`;
  } else if (status === 401) {
    throw "sessionTimeout";
  }
  try {
    result = await resJSON.json();
  } catch (err) {
    result = { error: SOMETHING_WENT_WRONG };
  }
  if (status !== 200) {
    throw result.error;
  }
  return result;
};

const getHeadersObject = () => {
  return {
    "Content-Type": "application/json",
    // "ngrok-skip-browser-warning": "69420",
  };
};

export const getWrapper = async (url, body = {}) => {
  const headers = getHeadersObject();
  const resJSON = await fetch(url, {
    method: "GET",
    headers,
  });
  return resJSON;
};

export const getBlogs = async () => {
  const resJSON = await getWrapper(BLOGS);
  const result = await returnOrThrow(resJSON);
  return result;
};

export const getBlogDetail = async (id) => {
  const resJSON = await getWrapper(`${BLOGS}/${id}`);
  const result = await returnOrThrow(resJSON);
  return result;
};

export const getCaseStudies = async (type = "") => {
  const resJSON = await getWrapper(`${CASE_STUDIES}?type=${type}`);
  const result = await returnOrThrow(resJSON);
  return result;
};

export const getCaseStudyDetails = async (id) => {
  const resJSON = await getWrapper(`${CASE_STUDIES}/${id}`);
  const result = await returnOrThrow(resJSON);
  return result;
};
