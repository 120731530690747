import { images } from "./images";

export const PORTFOLIOS = [
  {
    name: "avocure",
    image: images?.avocurePortfolio,
    portfolioCard: images?.avocurePortfolio,
    category: "healthcare",
    title: "Avocure",
    subTitle: "Facilitates end to end journey of patients",
    heading:
      "Avocure, facilitates end to end medical travel journey of Patients.We help patients travelling abroad for their treatment or medical travellers, make an informed decision regarding their choice of doctor/hospital.We provide these patients assistance in finding the best doctors / vaidyas and hospitals / wellness centres at a price that fits your budget, and search criteria like preference for a particular region/city, Ayurveda based treatment desired etc. Every Hospital/Wellness Centre’s page will give you an overview of the details of the procedure, a brief introduction to the centre and its staff. You will also be able to get a brief tour of the facility through high resolution images.",
    problem:
      "Avocure team hired us in an ongoing project so that we could take over the development part & they could focus on marketing.",
    solution:
      "We developed those features in ruby on rails as per client's requirements & satisfaction.",
    technologies: [
      "Ruby on Rails",
      "jQuery",
      "HTML5",
      "CSS3",
      "Mysql2",
      "AJAX",
      "Test Driven Development",
      "JSON API",
    ],
    url: "https://www.avocure.com/",
    forHomePage: true,
    homePageImage: images?.avocureClient,
  },
  {
    name: "healthsoul",
    image: images?.healthsoulPortfolio,
    portfolioCard: images?.healthsoulPortfolio,
    category: "healthcare",
    title: "Healthsoul",
    subTitle: "World's largest directory of healthcare reviews",
    heading:
      "Powered by an advanced practice growth engine, HealthSoul is an all-in-one digital marketing platform focused on healthcare providers and improved patient acquisition through an enhanced online presence. With our 360-degree digital marketing solutions and proven processes, we help providers succeed and improve their patient experience.\n Our mission is to provide effective digital marketing solutions to ensure that you have a consistent flow of patients and your practice stays ahead and stands apart, now and in the future.",
    problem:
      "Our client needed changes to the website for improving seo rankings",
    solution: "We made necessary changes to the website",
    technologies: ["Ruby on Rails", "HTML5", "SEO"],
    url: "https://www.healthsoul.com/",
    forHomePage: true,
    homePageImage: images?.healthsoulClient,
  },
  {
    name: "hundred",
    image: images?.hundredPortfolio,
    portfolioCard: images?.hundredPortfolio,
    category: "healthhcare",
    title: "Hundred",
    subTitle: "Vitamin & supplements store",
    heading:
      "60% of health issues are connected to our lifestyle behaviors. That's why hundred's health plan is based on the four pillars to wellness: nutrition, sleep, stress balance, and movement - ensuring your vitamin plan is fully integrated with your health goals. They provide personalised vitamins based on requirement.",
    problem:
      "Hundred team hired us on an ongoing project and added us in their development team. They wanted to improve the development speed of the project.",
    solution:
      "We developed the required features in ruby on rails as per client's requirements & satisfaction.",
    technologies: [
      "Ruby on Rails",
      "Spree",
      "jQuery",
      "HTML5",
      "CSS3",
      "PostgreSQL",
      "AJAX",
      "Test Driven Development",
      "JSON API",
    ],
    url: "https://www.joinhundred.com/",
    forHomePage: true,
    homePageImage: images?.hundredClient,
  },
  {
    name: "jetcoin",
    image: images?.jetcoinPortfolio,
    portfolioCard: images?.jetcoinPortfolio,
    category: "fintech",
    title: "Jetcoin",
    subTitle: "A cryptocurrency platform based in Singapore.",
    heading:
      "Jetcoin is a new digital fuel for the world of sports and entertainment, gives fans and supporters a unique opportunity to benefit directly from the success of their favorite athletes and stars. It disrupts traditional fan-athlete/talent relationships by enabling anyone to launch and support the careers of tomorrow's stars. Jetcoin Institute manages the distribution process of the Jetcoins via Jetcoin Contracts and proofs of social engagement (P.O.S.E.).",
    problem:
      "Jetcoin team hired us to develop a platform distribution process of the digital fuel, jetcoins (JET), via Jetcoin Contracts (JCs) and proof of social engagement (P.O.S.E.).",
    solution:
      "We developed those features in ruby on rails as per client's requirements & satisfaction.",
    technologies: [
      "Ruby on Rails",
      "jQuery",
      "HTML5",
      "CSS3",
      "PostgreSQL",
      "AJAX",
      "Test Driven Development",
      "JSON API",
    ],
    url: "https://www.jetcoin.io/",
    forHomePage: true,
    homePageImage: images?.jetcoinClient,
  },
  {
    name: "mad_optimist",
    image: images?.madoptimistPortfolio,
    portfolioCard: images?.madoptimistPortfolio,
    title: "Mad Optimist",
    subTitle: "A studio to build & order custom bathcare solutions.",
    heading:
      "The Mad Optimist is based in Bloomington, Indiana and owned and operated by three best friends and soapsmiths—brothers Mohammed A. and Mohammed M. Mahdi and Anthony Duncan. They have mad respect for Mother Nature, that’s why want to use only all-natural, plant-based ingredients inside every product. There USP is that their bodycare is vegan, cruelty-free, always all-natural, halal, non-GMO, and made from scratch to exact specifications.",
    problem:
      "The Mad Optimist team hired us to develop a platform so that customers can build custom bathcare products of their choice and order them.",
    solution:
      "We developed those features in ruby on rails as per client's requirements & satisfaction.",
    technologies: [
      "Ruby on Rails",
      "jQuery",
      "HTML5",
      "CSS3",
      "PostgreSQL",
      "AJAX",
      "Test Driven Development",
      "JSON API",
    ],
    url: "https://www.themadoptimist.com/",
    forHomePage: true,
    homePageImage: images?.madoptimistClient,
    width: 60,
  },
  {
    name: "dehub360",
    image: images?.dehub360Portfolio,
    portfolioCard: images?.dehub360Portfolio,
    title: "Dehub 360",
    subTitle: "Dehub VR School",
    heading:
      "DEHUB Provides visualization solutions for civil and other industries using technologies like Virtual Reality and Augmented Reality.",
    problem:
      "Dehub team hired us to develop a portal to so that students in Kerala can join and learn various courses in the field of VR, AR, MR techs.",
    solution:
      "We developed those features in ruby on rails as per client's requirements & satisfaction.",
    technologies: [
      "Ruby on Rails",
      "jQuery",
      "HTML5",
      "CSS3",
      "PostgreSQL",
      "AJAX",
      "Test Driven Development",
      "JSON API",
    ],
    url: "https://www.linkedin.com/company/dehub-360/about/",
  },
  {
    name: "stories_album_pro",
    image: images?.storiesalbumproPortfolio,
    portfolioCard: images?.storiesalbumproPortfolio,
    title: "Stories Album Pro",
    subTitle: "Stories Album Pro allows you to create live photos and albums.",
    heading:
      "Augmented reality platform for photo and arts. The platform where any entrepreneur and artist can create AR in 3 steps, build into a project and increase own profit.",
    problem:
      "Stories Album team hired us to create a feature that can create live photos and album.",
    solution:
      "We developed those features in ruby on rails as per client's requirements & satisfaction.",
    technologies: [
      "Ruby on Rails",
      "jQuery",
      "HTML5",
      "CSS3",
      "PostgreSQL",
      "AJAX",
      "Test Driven Development",
      "JSON API",
    ],
    url: "https://pro.stories-album.com/",
    forHomePage: true,
    homePageImage: images?.storiesalbumproClient,
  },
  {
    name: "a1_signs",
    image: images?.a1signsPortfolio,
    portfolioCard: images?.a1signsPortfolio,
    title: "A1 Signs",
    subTitle: "A one-stop-shop for all of your signage needs.",
    problem:
      "A1 Tools & Fixings hired us to create an e-commerce platform for their signage & design tools.",
    solution:
      "We developed those features in ruby on rails as per client's requirements & satisfaction.",
    technologies: [
      "Ruby on Rails",
      "jQuery",
      "HTML5",
      "CSS3",
      "PostgreSQL",
      "AJAX",
      "Test Driven Development",
      "JSON API",
    ],
    url: "https://a1signs-designs.herokuapp.com/",
  },
  {
    name: "table4u",
    image: images?.table4uPortfolio,
    portfolioCard: images?.table4uPortfolio,
    title: "Table 4U",
    subTitle: "Book a table at your favorite restaurant in moments!",
    problem:
      "Table 4U team hired us to develop a portal to so that customers can book a table in various resutrants in Poland. Resturant onwners can add their resturants to the portal & mangae the bookings.",
    solution:
      "We developed those features in ruby on rails as per client's requirements & satisfaction.",
    technologies: [
      "Ruby on Rails",
      "jQuery",
      "HTML5",
      "CSS3",
      "PostgreSQL",
      "AJAX",
      "Test Driven Development",
      "JSON API",
    ],
    url: "https://www.table-4-u.com/",
  },
  {
    name: "whoobid",
    image: images?.whoobidPortfolio,
    portfolioCard: images?.whoobidPortfolio,
    category: "realestate",
    title: "Whoobid",
    subTitle: "Innovative online property auction platform.",
    heading:
      "Whoobid is an innovative online property auction platform revolutionizing the way people buy and sell property. Online agencies are having an ever increasing market share as consumer demands are leading towards online services. Whoobid is promoting openness and transparency within the markets meaning properties are selling for their true market value. Gone are the days of old fashioned inefficient auction room sales which are often inflexible, out of area and poorly advertised. They harness the marketing expertise and experience of local agents but combine this with the innovation of our platform to help agents, sellers and buyers alike. As a company the primary focus is on developing and maintaining great client relationships, working together to give mutual clients the best possible experience.",
    problem:
      "Whoobid team hired us to create an auction platform for property listings in UK.",
    solution:
      "We developed those features in ruby on rails as per client's requirements & satisfaction.",
    technologies: [
      "Ruby on Rails",
      "jQuery",
      "HTML5",
      "CSS3",
      "PostgreSQL",
      "AJAX",
      "Test Driven Development",
      "JSON API",
    ],
    url: "https://www.whoobid.co.uk/",
    forHomePage: true,
    homePageImage: images?.whoobidClient,
    width: 120,
  },
  {
    name: "whats_app_resellers",
    image: images?.watchPortfolio,
    portfolioCard: images?.watchPortfolio,
    title: "Whats App Resellers",
    subTitle: "Reselling Platform for Fashion Products",
    problem:
      "Whats App Resellers hired us to create an portal for fashion products where users can place and track their orders.",
    solution:
      "We developed those features in ruby on rails as per client's requirements & satisfaction.",
    technologies: [
      "Ruby on Rails",
      "jQuery",
      "HTML5",
      "CSS3",
      "PostgreSQL",
      "AJAX",
      "Test Driven Development",
      "JSON API",
    ],
  },
  {
    name: "eubep",
    image: images?.eubepPortfolio,
    portfolioCard: images?.eubepPortfolio,
    title: "EUBEP",
    subTitle: "Multilingual Job Portal in Germany",
    problem:
      "EUBEP hired us to create a multilingual job portal for people looking for job in Germany.",
    solution:
      "We developed those features in ruby on rails as per client's requirements & satisfaction.",
    technologies: [
      "Ruby on Rails",
      "jQuery",
      "HTML5",
      "CSS3",
      "PostgreSQL",
      "AJAX",
      "Test Driven Development",
      "JSON API",
    ],
    url: "https://www.facebook.com/eubep/",
  },
  {
    name: "endless_factory",
    image: images?.endlessfactoryPortfolio,
    portfolioCard: images?.endlessfactoryPortfolio,
    title: "Endless Factory",
    subTitle: "A multi-seller E-commerce Platform.",
    problem:
      "The Endless Factory team hired us to develop a multi seller E-commerce platform where sellers can list their product for sale.",
    solution:
      "We developed those features in ruby on rails, react native and django as per client's requirements & satisfaction.",
    technologies: [
      "Ruby on Rails",
      "Django",
      "React Native",
      "HTML5",
      "CSS3",
      "PostgreSQL",
      "AJAX",
      "Test Driven Development",
      "JSON API",
    ],
    url: "https://www.endlessfactory.com/",
  },
  {
    name: "shopse",
    image: images?.shopsePortfolio,
    portfolioCard: images?.shopsePortfolio,
    category: "fintech",
    title: "Shopse",
    subTitle: "Cardless EMI, seconds mein done.",
    heading:
      "ShopSe is India’s largest EMI network. With ShopSe, Indian consumers can avail No-Cost EMI benefits on any purchase with wide range of partnered merchants for online and In-store shopping across 100+ Indian cities.",
    problem:
      "The ShopSe team hired us to develop an app for their business that could help Indian consumers avail no-cost EMI benefits on any purchase with wide range of partnered merchants for online and in-store shopping across 100+ Indian Cities.",
    solution:
      "We developed those features in React Js and React native as per client's requirements & satisfaction.",
    technologies: ["React Native", "React Js"],
    url: "https://play.google.com/store/apps/details?id=com.shopse.sales",
  },
  {
    name: "owl_print",
    image: images?.owlprintPortfolio,
    portfolioCard: images?.owlprintPortfolio,
    title: "Owl Prints",
    subTitle: "Create and Sell Products with Print on Demand Drop Shipping.",
    heading:
      "Owl Prints is your Print On Demand,  Drop shipping service provider that aims to uplift brands. They will print, pack and ship your orders to your customers under your brand name, with no minimums!!! From the Point of Purchase to the Point of Sale, our automated services are designed meticulously to ensure a smooth functioning. At Owl Prints, we pride ourselves in our immaculate attention to detail, quality and seamlessness of our operations.",
    problem:
      "The Owl Print team hired us to develop an app for their business that could bridge the gap between the customer and the merchant.",
    solution:
      "We developed those features in ruby on rails, react native and django as per client's requirements & satisfaction.",
    technologies: ["React Native"],
    url: "https://www.owlprints.in/",
  },
  {
    name: "pub_live",
    image: images?.publivePortfolio,
    portfolioCard: images?.publivePortfolio,
    title: "PubLive",
    subTitle: "The Best CMS for Modern Publishers.",
    heading:
      "Publive augments your capabilities to create the most relevant content at the right time. In this ever-changing digital ecosystem, this is already solved by PubLive - poor loading time, unpredictable SEO updates, inefficient content distribution & poor infra.",
    problem:
      "The Pub Live team hired us to develop a web Platform for their business solution that would help the modern publishers with best in class CMS service that will have BETTER CORE WEB VITALS, MORE ORGANIC TRAFFIC and BETTER CONTENT DISTRIBUTION.",
    solution:
      "We developed those features in React Js, Django and Python as per client's requirements & satisfaction.",
    technologies: ["Python", "React Js", "Django", "HTML5", "CSS3"],
    url: "https://thepublive.com/",
  },
  {
    name: "calwinkle",
    image: images?.publivePortfolio,
    portfolioCard: images?.publivePortfolioCard,
    title: "PubLive",
    subTitle: "The Best CMS for Modern Publishers.",
    heading:
      "Publive augments your capabilities to create the most relevant content at the right time. In this ever-changing digital ecosystem, this is already solved by PubLive - poor loading time, unpredictable SEO updates, inefficient content distribution & poor infra.",
    problem:
      "The Pub Live team hired us to develop a web Platform for their business solution that would help the modern publishers with best in class CMS service that will have BETTER CORE WEB VITALS, MORE ORGANIC TRAFFIC and BETTER CONTENT DISTRIBUTION.",
    solution:
      "We developed those features in React Js, Django and Python as per client's requirements & satisfaction.",
    technologies: ["Python", "React Js", "Django", "HTML5", "CSS3"],
    forHomePage: true,
    homePageImage: images?.carwinkleClient,
    isPortfolio: false,
  },
  {
    name: "crowdfrica",
    type: "new",
    industry: "Crowdfunding",
    services: "Web Development Support",
    image: images?.crowdAfricaPortfolio,
    portfolioCard: images?.crowdAfricaPortfolio,
    title: "Crowdfrica",
    subTitle: "Empowering Crowdfunding for Africa Through Development Support",
    heading:
      "Crowdfrica is transforming crowdfunding in Africa by allowing people within the community to raise funds for projects they deeply understand. This community-led approach ensures that the funding addresses real needs, creating a more effective and impactful process for local development.",
    problem:
      "Crowdfrica’s team needed to prioritize marketing efforts to expand their reach and attract more contributors. However, managing the development of their platform was time-consuming and diverted focus from their marketing goals. They required a development partner to take over the technical aspects, ensuring smooth operations while they concentrated on growing their user base and engagement.",
    solution:
      "We were hired to handle the development of Crowdfrica’s platform, allowing their team to focus entirely on marketing. Our role was to maintain and improve the platform’s functionality, ensuring it remained user-friendly and scalable.",
    results: [
      "Increased Focus on Marketing: With the development work handled, Crowdfrica’s team could fully dedicate their efforts to marketing and expanding their platform’s visibility.",
      "Platform Stability: The platform continued to perform reliably, providing users with a seamless experience while contributing to community projects.",
      "Scalability: Our development work ensured the platform could scale as more users joined, allowing Crowdfrica to expand its impact across different communities in Africa.",
    ],
    conclusion:
      "By taking over the development of the platform, we enabled Crowdfrica to focus on driving growth through marketing. The partnership led to a more efficient operation, supporting their mission to revolutionize crowdfunding across Africa.",
    url: "https://www.crowdfrica.org/",
  },
  {
    name: "kater_blue",
    type: "new",
    industry: "Nautical Leisure and Work",
    services: "Web Development Support",
    image: images?.katerbluePortfolio,
    portfolioCard: images?.katerbluePortfolio,
    title: "KaterBlue",
    subTitle: "Streamlining Nautical Leisure and Work with Development Support",
    heading:
      "KaterBlue is a comprehensive platform for boating enthusiasts, offering boat charters, nautical services, and a community for skippers, sailors, and boat owners. Whether users are looking to rent vessels, offer services, or gather sea miles for certifications, KaterBlue connects them all.",
    problem:
      "KaterBlue’s team needed to focus on business management while continuing to scale their platform. However, managing ongoing development work was time-consuming and hindered their ability to concentrate on growth.",
    solution:
      "We were brought in to handle the development of the platform, taking over the technical responsibilities so the KaterBlue team could focus on business operations. Our role was to maintain and improve the platform, ensuring a seamless user experience for both boat owners and enthusiasts.",
    results: [
      "Increased Focus on Business: The KaterBlue team was able to shift their attention entirely to managing and expanding their business.",
      "Platform Stability and Growth: The platform continued to grow smoothly, with enhanced features and seamless functionality, attracting more users and facilitating successful transactions.",
      "User Satisfaction: Users experienced a streamlined process for chartering boats, connecting with skippers, and accessing other nautical services, improving overall satisfaction.",
    ],
    conclusion:
      "By handling KaterBlue’s platform development, we enabled their team to focus on scaling the business and improving operations. The result was a well-maintained, efficient platform that continues to serve the needs of the nautical community.",
    url: "katerblue.com",
  },
  {
    name: "hcah",
    type: "new",
    industry: "Healthcare (Rehabilitation Services)",
    services: "Software Development Support",
    image: images?.hcahPortfolio,
    portfolioCard: images?.hcahPortfolio,
    title: "HCAH",
    subTitle: "Accelerating Software Development for Out-of-Hospital Care",
    heading:
      "HCAH is India’s largest out-of-hospital care provider, specializing in medical rehabilitation for stroke, brain injuries, Parkinson’s, Alzheimer’s, and more. With over 25,000 successful recoveries and 1,000+ expert professionals, they lead the industry in patient care across five major cities.",
    problem:
      "HCAH’s team needed to focus on business management but was slowed down by the demands of software development. They required a dedicated development partner to increase the speed of software delivery.",
    solution:
      "We joined HCAH’s development team, taking over technical tasks to accelerate their software development process. This allowed their internal team to concentrate on managing and growing their rehabilitation services.",
    results: [
      "Faster Software Development: Our involvement led to quicker rollouts of new features and updates, enhancing HCAH’s operational efficiency.",
      "Business Focus: The HCAH team could focus on expanding their core healthcare services without being hindered by technical delays.",
      "Enhanced Service Delivery: With improved software, HCAH’s state-of-the-art rehabilitation centers operated more smoothly, benefitting both patients and professionals.",
    ],
    conclusion:
      "By taking over the development workload, we empowered HCAH to focus on scaling their out-of-hospital care services, leading to improved patient outcomes and business growth.",
    url: "Hcah.in",
  },
  {
    name: "mginne",
    type: "new",
    industry: "Retail Technology, Social Commerce",
    services: "Software Development Support",
    image: images?.mginnePortfolio,
    portfolioCard: images?.mginnePortfolio,
    title: "MGINNE Private Limited",
    subTitle: "Speeding Up Development for Retail Tech and Social Commerce",
    heading:
      "MGINNE is a technology start-up in the retail sector, operating India’s first social commerce platform focused on health and wellness. They are at the forefront of integrating technology into retail and wellness industries.",
    problem:
      "MGINNE needed to accelerate their software development to meet business demands but lacked the resources to manage both development and business operations. They required external support to handle the technical side.",
    solution:
      "We joined their development team to increase the speed of software development. This allowed the MGINNE team to focus on business management and scaling their innovative platform.",
    results: [
      "Faster Time to Market: Our support sped up the development process, enabling quicker feature rollouts.",
      "Business Focus: MGINNE’s team could fully dedicate their efforts to managing the business, improving growth and user engagement.",
      "Enhanced Platform Performance: The social commerce platform benefited from streamlined development, improving user experience.",
    ],
    conclusion:
      "By taking on the development workload, we enabled MGINNE to focus on expanding their retail technology and social commerce initiatives, accelerating both technical progress and business growth.",
  },
  {
    name: "trip_scam",
    type: "new",
    industry: "Retail Technology, Social Commerce",
    services: "Mobile App Development",
    image: images?.tripScamPortfolio,
    portfolioCard: images?.tripScamPortfolio,
    title: "TripScam",
    subTitle: "Launching a Mobile App for Safe and Transparent Travel",
    heading:
      "TripScam is the first platform where travelers can report and avoid scams, frauds, and negative travel experiences. It promotes transparent and honest travel services while offering expert advice from seasoned travelers.",
    problem:
      "TripScam’s team needed a mobile app to bring their platform to the market, but they required external support for the technical development, allowing them to focus on growing their business.",
    solution:
      "We were hired to handle the mobile app development, ensuring it launched smoothly while the TripScam team focused on business operations.",
    results: [
      "Successful App Launch: The app was developed and launched, making TripScam’s platform easily accessible to travelers.",
      "Business Focus: TripScam’s team could concentrate on marketing and growing their user base, knowing the technical side was taken care of.",
    ],
    conclusion:
      "Our mobile app development support helped TripScam launch their platform efficiently, enabling them to focus on expanding their mission of providing safe and transparent travel experiences.",
  },
  {
    name: "tru_beauty",
    type: "new",
    industry: "Hair Care and Salon Equipment",
    services: "Mobile App & Website Development",
    image: images?.trubeautyPortfolio,
    portfolioCard: images?.trubeautyPortfolio,
    title: "Trü Beauty",
    subTitle: "Launching a Mobile App and Website for Salon Services",
    heading:
      "Trü Beauty, a family-operated business in Alberta, provides healthier, high-performance hair care products and premium salon equipment. Their products are exclusively for salons, ensuring safety for both salon guests and employees.",
    problem:
      "Trü Beauty needed a digital presence to expand their market reach. They required a mobile app and website to sell their salon services and products online.",
    solution:
      "We developed a user-friendly mobile app and website, enabling Trü Beauty to offer their services and products online seamlessly.",
    results: [
      "Increased Sales: The online platform helped Trü Beauty reach a wider audience, boosting sales.",
      "Streamlined Operations: The mobile app provided salons with an easy way to access and purchase products and equipment.",
    ],
    conclusion:
      "Our development efforts helped Trü Beauty expand into the digital space, enhancing their service delivery and increasing their market presence.",
    url: "https://trubeauty.ca/",
  },
  {
    name: "prevalearn",
    type: "new",
    industry: "Education Technology (EdTech)",
    services: "Mobile App & Website Development",
    image: images?.prevalearnPortfolio,
    portfolioCard: images?.prevalearnPortfolio,
    title: "Prevalearn",
    subTitle: "Launching an EdTech Mobile App and Website",
    heading:
      "Prevalearn is designed to make knowledge sharing easy for experts across various fields, offering tools to build and manage their online courses and business. It allows educators to focus on inspiring learners while the platform handles the technical and business side.",
    problem:
      "Prevalearn needed a mobile app and website to bring their platform to market, allowing experts to create and share their knowledge effectively.",
    solution:
      "We developed an intuitive mobile app and website, enabling Prevalearn to launch and provide seamless services for educators and learners.",
    results: [
      "Successful Market Launch: The platform was successfully launched, empowering experts to share their knowledge with ease.",
      "Increased Engagement: Educators were able to easily create courses, manage learners, and grow their communities.",
    ],
    conclusion:
      "Our development efforts helped Prevalearn enter the EdTech market, providing a user-friendly platform that fosters knowledge sharing and business growth.",
    forHomePage: true,
    homePageImage: images?.prevalearnClient,
    isPortfolio: true,
  },
  {
    name: "plus_vibes",
    type: "new",
    industry: "Mental Health",
    services: "Mobile App Development",
    image: images?.plusvibesPortfolio,
    portfolioCard: images?.plusvibesPortfolio,
    title: "PlusVibes",
    subTitle: "Launching a Mental Health Mobile App",
    heading:
      "PlusVibes is a platform dedicated to integrating mental health resources, including associations, volunteered listeners, and campaigners. It aims to reduce stigma around mental health and provide a supportive avenue for individuals seeking advice or motivation.",
    problem:
      "PlusVibes needed a healthcare mobile app developed in React Native for both Android and iOS to facilitate anonymous support and self-development.",
    solution:
      "We developed a user-friendly mobile app that connects users to mental health resources and communities, promoting anonymous interaction and motivation.",
    results: [
      "Successful Market Launch: The app was launched effectively, providing users with immediate access to mental health support.",
      "Enhanced Engagement: Users can seek advice and motivation in a safe, anonymous environment, fostering community support.",
    ],
    conclusion:
      "Our development efforts enabled PlusVibes to launch a vital mobile app, promoting mental health awareness and support in a user-friendly format.",
    url: "https://apps.apple.com/my/app/plusvibes-support-motivate/id1527964151?platform=iphone",
  },
  {
    name: "aoh",
    type: "new",
    industry: "Healthcare Technology",
    services: "Mobile App Development",
    image: images?.aohPortfolio,
    portfolioCard: images?.aohPortfolio,
    title: "At-Once-Health",
    subTitle: "Developing a Mobile App for Personalized Healthcare",
    heading:
      "At-Once-Health (AOH) is an eCommerce platform designed to create a personalized digital identity for healthcare providers, including doctors, polyclinics, and hospitals. By offering tailored web portals and mobile applications, AOH aims to enhance the healthcare experience for both providers and patients.",
    problem:
      "AOH sought to develop two mobile applications—one for patients and one for doctors—using React Native for Android and iOS. These apps needed to facilitate appointment bookings, prescription management, and overall healthcare service access.",
    solution:
      "We developed a user-friendly mobile platform that allows patients to book appointments, manage prescriptions, and access health services efficiently. The platform features a customizable Electronic Medical Record (EMR) system, enabling doctors to issue prescriptions during video consultations or in-person visits while maintaining accurate medical histories.",
    results: [
      "Successful Launch: Both mobile applications were launched, enhancing accessibility to healthcare services.",
      "Improved User Experience: Patients can easily manage appointments and prescriptions, while doctors can efficiently handle patient records and communications.",
    ],
    conclusion:
      "Our development partnership enabled At-Once-Health to establish a robust mobile healthcare platform, significantly improving interactions between healthcare providers and patients.",
    url: "https://aoh.ai/#About",
  },
  {
    name: "taj_flights",
    type: "new",
    industry: "Travel & Tourism",
    services: "Web Platform Development",
    image: images?.travelPortalPortfolio,
    portfolioCard: images?.travelPortalPortfolio,
    title: "Taj Flights",
    subTitle: "Developing a Travel Agency Platform",
    heading:
      "Taj Flights, a travel agency based in Rome, Italy, purchases airline tickets in bulk and sells them to sub-agents, setting minimum prices based on class and travel dates.",
    problem:
      "Taj Flights needed a robust web platform to manage ticket inventory, pricing, and agent transactions in an efficient and scalable way.",
    solution:
      "We developed a travel agency platform with a Ruby on Rails backend and React.js frontend. The system manages bulk ticket purchasing, sets dynamic prices, and enables smooth interactions with sub-agents.",
    results: [
      "Streamlined Operations: The platform simplified bulk ticket management.",
      "Increased Efficiency: Sub-agents could access and purchase tickets with ease.",
      "Market Launch: The system was successfully launched, enhancing overall business efficiency.",
    ],
    conclusion:
      "Market Launch: The system was successfully launched, enhancing overall business efficiency.",
  },
  {
    name: "aquifer",
    type: "new",
    industry: "Healthcare Education",
    services: "Web Platform Development",
    image: images?.travelPortalPortfolio,
    portfolioCard: images?.travelPortalPortfolio,
    title: "Aquifer",
    subTitle: "Aquifer",
    heading:
      "Aquifer, a non-profit organization, focuses on delivering innovative virtual learning for healthcare education. Since its founding in 2006, Aquifer has seen over 15 million virtual patient cases completed.",
    problem:
      "Aquifer required a web platform to manage its educational content, deliver virtual patient cases, and scale for growing user demands in health professions education.",
    solution:
      "We developed Aquifer’s healthcare education platform using Ruby on Rails for the backend and React.js for the frontend. The platform supports interactive, virtual case-based learning and collaborative development.",
    results: [
      "Streamlined Operations: The platform simplified bulk ticket management.",
      "Increased Efficiency: Sub-agents could access and purchase tickets with ease.",
      "Market Launch: The system was successfully launched, enhancing overall business efficiency.",
    ],
    conclusion:
      "Market Launch: The system was successfully launched, enhancing overall business efficiency.",
  },
  {
    name: "bizbook",
    image: images?.bizbookPortfolio,
    portfolioCard: images?.bizbookPortfolio,
    title: "BizBook",
    subTitle: "An application to manage finance log book",
    problem:
      "Client hired us to create a mobile application where users can manage their credit/debit log book. They also wanted a web admin panel so that the application administrators can manage various users in the application.",
    solution:
      "We developed those features in ruby on rails as per client's requirements & satisfaction.",
    technologies: [
      "Ruby on Rails",
      "jQuery",
      "HTML5",
      "CSS3",
      "PostgreSQL",
      "AJAX",
      "Test Driven Development",
      "Flutter",
      "JSON API",
    ],
  },
  {
    name: "just_KIA",
    image: images?.justkiaPortfolio,
    portfolioCard: images?.justkiaPortfolio,
    title: "Just Kia",
    subTitle: "KAAM ITNA ASAAN.",
    heading:
      "A tool for business communities & vendors in KIA association in Haryana to connect & do business with each other.",
    problem:
      "The Just KIA team hired us to develop a Mobile App Platform. A tool for business communities and vendors in KUNDLI INDUSTRIAL ASSOCIATION in HARYANA to connect and do efficient business with each other.",
    solution:
      "We developed those features in React Native and Ruby on Rails as per client's requirements & satisfaction.",
    technologies: ["Ruby on Rails", "React Native"],
    url: "https://play.google.com/store/apps/details?id=com.kiaapp",
  },
  {
    name: "timbuktu",
    image: images?.timbuktuPortfolio,
    portfolioCard: images?.timbuktuPortfolio,
    title: "Timbuktu",
    subTitle: "Empowering Talent and Creativity in India",
    heading:
      "Timbuktu is a Made in India platform that organizes online talent competitions across mass entertainment and lifestyle genres. The platform allows users to judge competitions, participate in competitions, or organize their own competitions.",
    challenge:
      "<p class='tc-primary fs-26 py-2'>Timbuktu approached our team with the goal of developing a mobile app platform that would allow users to:</p><ol><li>1.⁠ ⁠Earn real cash directly in their bank accounts</li><li>2.⁠ ⁠Participate in various competitions</li><li>3.⁠ ⁠Judge competitions using their content knowledge</li><li>4.⁠ ⁠Organize competitions for their communities</li></ol><p class='tc-primary fs-26 py-2''>The client's vision was to create a creator launchpad that identifies and promotes promising talent, powered by community engagement.<br>&nbsp;</p>",
    problem:
      "The Timbuktu team hired us to develop a Mobile App Platform, that will help the users earn real cash directly in their bank account using the app. They can participate in various competitions as well.",
    result:
      "<p class='tc-primary fs-26 py-2'>We developed a comprehensive mobile application using React Native for the frontend and Ruby on Rails for the backend. The solution included the following key features:</p><ol><li>⁠User authentication and profile management</li><li>Competition creation and management tools</li><li>⁠Judging interface with scoring mechanisms</li><li>⁠Participant submission and tracking system</li><li>⁠Secure payment integration for cash rewards</li><li>Community engagement features</li></ol><p class='tc-primary fs-26 py-2'>Implementation Process</p><ol><li>⁠Requirements Gathering: We conducted thorough discussions with the Timbuktu team to understand their vision and specific needs.</li><li>Design Phase: Our team created intuitive designs focusing on ease of use and engagement.</li><li>Development: We used an Agile methodology, with regular sprints and client feedback sessions.</li><li>Testing: Rigorous testing was performed to ensure app stability, security, and performance across various devices.</li><li>⁠Deployment: The app was successfully launched on both iOS and Android platforms.</li></ol><h2 classname='tc-primary head-font py-3'><strong>Results and Benefits</strong></h2><ol><li>User Engagement: The app saw a significant uptake in user registrations and active participation in competitions.</li><li>Revenue Generation: Users were able to earn real cash, fostering a motivated and engaged community.</li><li>Cultural Promotion: The platform successfully promoted various art forms and cultural activities across India.</li><li>⁠Talent Discovery: Several promising artists gained recognition through the platform's competitions.</li><li>Community Building: The app facilitated the creation of niche communities around specific talents and interests.<br>&nbsp;</li></ol>",
    solution:
      "The Timbuktu app has successfully created a unique ecosystem for talent promotion and cultural engagement in India. By leveraging technology to connect artists, judges, and organizers, Timbuktu is paving the way for a new era of digital talent discovery and promotion.",
    technologies: ["Ruby on Rails", "React Native"],
    url: "https://www.playtimbuktu.com/",
  },
  {
    name: "alert_on_crises",
    type: "new",
    industry: "Emergency Management",
    services: "Mobile App Development",
    image: images?.alertOnCrisesPortfolio,
    portfolioCard: images?.alertOnCrisesPortfolio,
    title: "Alert on Crises",
    subTitle: "Developing a Mobile App for Emergency Preparedness",
    heading:
      "Alert on Crises provides a solution for individuals responsible for others during emergencies by digitizing Emergency Response Plans. Their platform puts essential emergency procedures directly onto smartphones, tablets, and desktops, ensuring that those who need them can access them quickly and easily.",
    problem:
      "The team needed a mobile app to launch their platform, enabling efficient access to emergency response information for users in crisis situations.",
    solution:
      "We developed a user-friendly mobile app that integrates seamlessly with various devices, allowing users to access emergency response plans at their fingertips.",
    results: [
      "Successful App Launch: The platform was effectively launched, enhancing accessibility to critical emergency information.",
      "Improved Crisis Response: Users can now access vital information quickly during emergencies, leading to more efficient response efforts.",
    ],
    conclusion:
      "Our development work helped Alert on Crises launch a valuable mobile app, empowering individuals with the tools needed to manage emergencies effectively.",
  },
  {
    name: "lic",
    image: images?.licPortfolio,
    portfolioCard: images?.licPortfolio,
    title: "LicAllInOne",
    subTitle: "App for agents of LIC Mauritius",
    heading:
      "The Mauritius Branch of Life Insurance Corporation of India was formed alongside its formation in India in 1956. Five India based private companies which existed in 1956 in Mauritius were amalgamated and thus was formed LIC Branch in Mauritius on 30th Nov 1956.The Total Assets in Mauritius is nearly Rs. 4.4 billion. Customer service has always been the focal point in all its operations. LIC Mauritius has settled claims amounting to Rs. 479.32Mn during 2019-20 which translates to a claim settlement ofaround Rs 2Mn every working day.LIC Mauritius is catering to the life insurance needs of the people of Mauritius by offering a wide range of 35life insurance products, ranging from term insurance, endowment, money back, combo products, single premium products, children policies, joint life insurance, Infinity, etc.",
    problem:
      "LIC Mauritius hired us to create a mobile application tool for their field agents so that they can generate and share policy quotations on the go for customers.",
    solution:
      "We developed the mobile application in Google Flutter as per client's requirements & satisfaction.",
    technologies: ["Android Development", "iOS Development", "Flutter"],
    forHomePage: true,
    homePageImage: images?.licClient,
    width: 120,
  },
  {
    name: "josts_se",
    image: images?.jostsPortfolio,
    portfolioCard: images?.jostsPortfolio,
    title: "Josts SE",
    subTitle: "Josts Engineering Company",
    heading:
      "JOST has worked hard to achieve its position as a global leader in the manufacturing of systems, modules and components for commercial vehicles. The five strong product brands JOST, ROCKINGER, TRIDEC and Quicke form the backbone of our organization. By combining the individual products, efficient concepts for customer-specific applications and future technology systems are created. This is a simple tool for service engineers working in Josts Engineering company to make their life simple and easy at work.",
    problem:
      "Josts hired us to create a simple tool for their service engineers working in Josts Engineering company.They also wanted a web admin panel so that the application administrators can manage their service engineers.",
    solution:
      "We developed those features in ruby on rails & Flutter as per client's requirements & satisfaction.",
    technologies: [
      "Ruby on Rails",
      "jQuery",
      "HTML5",
      "CSS3",
      "PostgreSQL",
      "AJAX",
      "Test Driven Development",
      "Flutter",
      "JSON API",
    ],
    url: "https://play.google.com/store/apps/details?id=com.josts.josts_foreman_app",
    forHomePage: true,
    homePageImage: images?.jostsClient,
  },
];
