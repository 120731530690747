import { images } from "../constants/images";
import { useNavigate } from "react-router-dom";

const PageNotFound = function () {
  const navigate = useNavigate();

  return (
    <div>
      <main
        className="wrapper"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img
          src={images?.pageNotFound}
          alt="404"
          style={{ maxWidth: "50%", maxHeight: "50%", alignSelf: "center" }}
        />

        <button
          onClick={() => {
            navigate("/");
          }}
          className="link_btn mt-4 mx-2"
          style={{ maxWidth: "70%" }}
        >
          Back to homepage
        </button>
      </main>
    </div>
  );
};

export default PageNotFound;
