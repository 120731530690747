export const images = {
  footerBg: require("../assets/images/footer-bg.png"),
  ruby: require("../assets/images/ruby.png"),
  whatsapp: require("../assets/images/whatsapp.png"),
  telephone: require("../assets/images/telephone.png"),
  email: require("../assets/images/email.png"),
  pluralcodeLogo: require("../assets/images/pluralcode_logo.png"),
  railsLogo: require("../assets/images/rails-logo.png"),
  html5Logo: require("../assets/images/HTML5.png"),
  cssLogo: require("../assets/images/logo_CSS3.png"),
  jekyllLogo: require("../assets/images/jekyll-logo.png"),
  jqueryLogo: require("../assets/images/jquery-logo.png"),
  mongodbLogo: require("../assets/images/mongodb.png"),
  flutterLogo: require("../assets/images/flutter-logo.jpg"),
  reactnativeLogo: require("../assets/images/reactnative-logo.png"),
  reactjsLogo: require("../assets/images/reactjs-logo.png"),
  pyhtonLogo: require("../assets/images/pyhton-logo.png"),
  postgresqlLogo: require("../assets/images/postgresql.png"),
  nodejsLogo: require("../assets/images/node-js.png"),
  djangoLogo: require("../assets/images/django-logo.png"),
  machinelearningLogo: require("../assets/images/machine-learning-logo.jpg"),
  hamlLogo: require("../assets/images/haml.png"),
  gitLogo: require("../assets/images/git-logo.png"),
  mysqlLogo: require("../assets/images/mysql.png"),
  railsServices: require("../assets/images/rails_services.png"),
  djangoServices: require("../assets/images/django.jpg"),
  reactServices: require("../assets/images/react.jpg"),
  pythonServices: require("../assets/images/python.jpg"),
  flutterServices: require("../assets/images/Flutter-app.png"),
  mlServices: require("../assets/images/ml.jpg"),
  customServices: require("../assets/images/custom.jpg"),
  staticServices: require("../assets/images/web-design.jpg"),
  rorBanner: require("../assets/images/banner/ruby_on_rails.jpg"),
  rnBanner: require("../assets/images/banner/react_native.jpeg"),
  webDesignBanner: require("../assets/images/banner/web_design.jpg"),
  wdBanner: require("../assets/images/banner/w_d.jpeg"),
  cSDBanner: require("../assets/images/banner/custom_software_development.jpg"),
  shopsePortfolio: require("../assets/images/portfolio/Shopse.png"),
  table4uPortfolio: require("../assets/images/portfolio/table4u.png"),
  whoobidPortfolio: require("../assets/images/portfolio/whoobid.png"),
  watchPortfolio: require("../assets/images/portfolio/watch.jpeg"),
  watchPortfolio: require("../assets/images/portfolio/watch.jpeg"),
  publivePortfolio: require("../assets/images/portfolio/publive.png"),
  owlprintPortfolio: require("../assets/images/portfolio/owl_print.png"),
  timbuktuPortfolio: require("../assets/images/portfolio/timbuktu.png"),
  justkiaPortfolio: require("../assets/images/portfolio/justkia.png"),
  endlessfactoryPortfolio: require("../assets/images/portfolio/endless_factory.png"),
  eubepPortfolio: require("../assets/images/portfolio/eubep.png"),
  bizbookPortfolio: require("../assets/images/portfolio/bizbook.jpeg"),
  jostsPortfolio: require("../assets/images/portfolio/josts.jpeg"),
  licPortfolio: require("../assets/images/portfolio/lic.jpeg"),
  a1signsPortfolio: require("../assets/images/portfolio/a1_signs.png"),
  storiesalbumproPortfolio: require("../assets/images/portfolio/stories_album_pro.png"),
  dehub360Portfolio: require("../assets/images/portfolio/dehub360.png"),
  madoptimistPortfolio: require("../assets/images/portfolio/mad_optimist.png"),
  jetcoinPortfolio: require("../assets/images/portfolio/jetcoin.png"),
  hundredPortfolio: require("../assets/images/portfolio/hundred.png"),
  healthsoulPortfolio: require("../assets/images/portfolio/healthsoul.png"),
  avocurePortfolio: require("../assets/images/portfolio/avocure.png"),
  designmysoapPortfolio: require("../assets/images/portfolio/design-my-soap.png"),
  avocureClient: require("../assets/images/clients/avocure.png"),
  jetcoinClient: require("../assets/images/clients/jetcoin.png"),
  healthsoulClient: require("../assets/images/clients/healthsoul.png"),
  madoptimistClient: require("../assets/images/clients/MadOptimistLogo.png"),
  prevalearnClient: require("../assets/images/clients/prevalearn.png"),
  whoobidClient: require("../assets/images/clients/whoobid.png"),
  licClient: require("../assets/images/clients/lic.png"),
  jostsClient: require("../assets/images/clients/josts.png"),
  hundredClient: require("../assets/images/clients/hundred.png"),
  storiesalbumproClient: require("../assets/images/clients/stories.png"),
  table4uClient: require("../assets/images/clients/tablen.jpg"),
  carwinkleClient: require("../assets/images/clients/calwinkle-logo.gif"),
  webDesign: require("../assets/images/web-design.png"),
  webDevelopemnt: require("../assets/images/web-development.jpg"),
  mobileDevelopment: require("../assets/images/mobile-developement.jpeg"),
  hundredPortfolioCard: require("../assets/images/portfolio/portfolio-cards/hundred.png"),
  healthsoulPortfolioCard: require("../assets/images/portfolio/portfolio-cards/healthsoul.png"),
  avocurePortfolioCard: require("../assets/images/portfolio/portfolio-cards/avocure.png"),
  a1signsPortfolioCard: require("../assets/images/portfolio/portfolio-cards/a1_signs.png"),
  designmysoapPortfolioCard: require("../assets/images/portfolio/portfolio-cards/design-my-soap.png"),
  endlessfactoryPortfolioCard: require("../assets/images/portfolio/portfolio-cards/endless_factory.png"),
  jetcoinPortfolioCard: require("../assets/images/portfolio/portfolio-cards/jetcoin.png"),
  madoptimistPortfolioCard: require("../assets/images/portfolio/portfolio-cards/mad_optimist.png"),
  dehub360PortfolioCard: require("../assets/images/portfolio/portfolio-cards/dehub360.png"),
  storiesalbumproPortfolioCard: require("../assets/images/portfolio/portfolio-cards/stories_album_pro.png"),
  licPortfolioCard: require("../assets/images/portfolio/portfolio-cards/lic.jpeg"),
  table4uPortfolioCard: require("../assets/images/portfolio/portfolio-cards/table4u.png"),
  jostsPortfolioCard: require("../assets/images/portfolio/portfolio-cards/josts.jpeg"),
  whoobidPortfolioCard: require("../assets/images/portfolio/portfolio-cards/whoobid.png"),
  watchPortfolioCard: require("../assets/images/portfolio/portfolio-cards/watch.jpeg"),
  eubepPortfolioCard: require("../assets/images/portfolio/portfolio-cards/eubep.png"),
  bizbookPortfolioCard: require("../assets/images/portfolio/portfolio-cards/bizbook.jpeg"),
  shopsePortfolioCard: require("../assets/images/portfolio/portfolio-cards/shopse_card.png"),
  justkiaPortfolioCard: require("../assets/images/portfolio/portfolio-cards/justkia.png"),
  timbuktuPortfolioCard: require("../assets/images/portfolio/portfolio-cards/timbuktu.jpeg"),
  owlprintPortfolioCard: require("../assets/images/portfolio/portfolio-cards/owl_print.png"),
  publivePortfolioCard: require("../assets/images/portfolio/portfolio-cards/publive.png"),
  pctLogo: require("../assets/images/pluralcode_transparent_logo.png"),
  aboutUs: require("../assets/images/about_us.jpg"),
  linkedin: require("../assets/images/linkedin.png"),
  linkedinDark: require("../assets/images/linkedin-dark.png"),
  staff: require("../assets/images/staff.jpeg"),
  values: require("../assets/images/corevalues.jpeg"),
  reactTechnology: require("../assets/images/react.png"),
  reactnativeTechnology: require("../assets/images/react_native.png"),
  rorTechnology: require("../assets/images/ror-logo.png"),
  lifecycle: require("../assets/images/lifecycle.png"),
  mlTechnology: require("../assets/images/ml1-removebg-preview.png"),
  cross: require("../assets/images/cross.png"),
  django: require("../assets/images/django.png"),
  pyhton: require("../assets/images/python.png"),
  tripScamPortfolio: require("../assets/images/portfolio/trip-scam.jpeg"),
  prevalearnPortfolio: require("../assets/images/portfolio/prevalearn.jpeg"),
  alertOnCrisesPortfolio: require("../assets/images/portfolio/alert_on_crises.jpeg"),
  crowdAfricaPortfolio: require("../assets/images/portfolio/crowdafrica.png"),
  katerbluePortfolio: require("../assets/images/portfolio/katerblue.png"),
  hcahPortfolio: require("../assets/images/portfolio/hcah.png"),
  mginnePortfolio: require("../assets/images/portfolio/mginne.jpeg"),
  tripscamPortfolio: require("../assets/images/portfolio/trip-scam.jpeg"),
  trubeautyPortfolio: require("../assets/images/portfolio/tru-beauty.png"),
  plusvibesPortfolio: require("../assets/images/portfolio/plusvibes.png"),
  aohPortfolio: require("../assets/images/portfolio/aoh.png"),
  travelPortalPortfolio: require("../assets/images/portfolio/travelportal.png"),
  erpDevelopment: require("../assets/images/erp.jpeg"),
  pageNotFound: require("../assets/images/404.jpeg"),
  expressJs: require("../assets/images/express.png"),
  reactJs: require("../assets/images/react-js.webp"),
  reactNative: require("../assets/images/react-native.png"),
  database: require("../assets/images/database.jpg"),
  cloud: require("../assets/images/cloud-ops.jpg"),
  api: require("../assets/images/api.jpg"),
};
