import { useState, useEffect } from "react";
// import RoutesList from '../constants/RoutesList';
// import { INFO, SESSION_EXPIRED, showSnackbar } from '../../utils';
import { useNavigate } from "react-router-dom";

const useFetch = (apiMethod, data) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [serverError, setServerError] = useState("");

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const result = await apiMethod(data);
      setApiData(result);
      setIsLoading(false);
    } catch (error) {
      if (error === "sessionTimeout") {
        // showSnackbar(SESSION_EXPIRED, INFO);
        localStorage.clear();
        // navigate(RoutesList.login);
      } else {
        setServerError(error);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { isLoading, apiData, serverError };
};

export default useFetch;
