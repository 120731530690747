import React from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export default function BlogCard({ item }) {
  const navigate = useNavigate();

  const toBlog = () => {
    navigate(`/blog/${item?.id}`, { state: { item: item?.id } });
  };

  return (
    <div className="col-lg-6 col-md-6 mb-5 px-4">
      <div
        className="cards"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <a onClick={toBlog} style={{ cursor: "pointer" }}>
          <img
            src={item?.banner}
            className="img-fluid"
            style={{
              height: 400,
              width: "100%",
              borderRadius: 5,
              objectFit: "fill",
              alignSelf: "center",
            }}
            alt={item?.title}
          />
          <h2 className="fs-18 tc-primary py-2 fw-bold">{item?.title}</h2>
          <p className="d-flex flex-wrap para-font align-items-center pt-3 fs-12">
            <strong className="tc-primary-light">{item?.author ?? "NA"}</strong>
            <span className="dot" />
            <strong>
              <strong className="tc-primary-light">
                {moment(item?.posted_at).fromNow() ?? "NA"}
              </strong>
            </strong>
          </p>
        </a>
      </div>
    </div>
  );
}
